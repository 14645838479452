import { Api, makeGetUrl } from '@/libraries/api'

async function getUserStatistics(userType, startDate, endDate) {
  const url = `/admin/statistics/${userType}`
  const params = {
    start_date: startDate,
    end_date: endDate
  }
  const result = await Api().get(makeGetUrl(url, params))
  const { item } = result.data.data
  return item
}

async function getCaregivers(startDate, endDate) {
  const item = await getUserStatistics('caregiver', startDate, endDate)
  return item
}

async function getPatients(startDate, endDate) {
  const item = await getUserStatistics('patient', startDate, endDate)
  return item
}

async function getMatch(startDate, endDate) {
  const url = `/admin/statistics/match`
  const params = {
    start_date: startDate,
    end_date: endDate
  }
  const result = await Api().get(makeGetUrl(url, params))
  const { item } = result.data.data
  return item
}

async function getProfit() {
  const url = `/admin/statistics/profit`
  const result = await Api().get(url)
  const { item } = result.data.data
  return item
}

export default {
  getPatients,
  getCaregivers,
  getMatch,
  getProfit
}
