<style scoped>
.simple-table {
  max-height: 400px;
  overflow: scroll;
}

.small-text {
  font-size: 10px;
  color: #505050;

  animation-name: example;
  animation-duration: 2.5s;
}

@keyframes example {
  from {
    background-color: blue;
    color: white;
  }
  to {
    background-color: white;
    color: #505050;
  }
}
</style>

<template>
  <v-container class="" fluid>
    <v-row justify="end">
      <v-col cols="auto">
        <v-btn v-if="useAutoSender" elevation="2" color="success" @click="setAutoSender" :disabled="buttonLoading"
          >문자 자동 보내기(on)</v-btn
        >
        <v-btn v-else elevation="2" color="#ababab" @click="setAutoSender" :disabled="buttonLoading"
          >문자 자동 보내기(off)</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6" md="3" cols="6">
        <SmallCard
          title="등록 환자"
          :number="$options.filters.numberFormat(patientData.total_count)"
          t-icon="person"
          t-icon-color=""
          b-icon=""
          b-icon-color="success"
          :b-text="`-`"
        />
      </v-col>
      <v-col sm="6" md="3" cols="6">
        <SmallCard
          title="등록 간병인"
          :number="$options.filters.numberFormat(carerData.total_count)"
          t-icon="assignment_ind"
          t-icon-color=""
          b-icon=""
          b-icon-color="success"
          :b-text="`-`"
        />
      </v-col>
      <v-col sm="12" md="6" cols="12">
        <SmallCard
          title="결제"
          :number="`${$options.filters.numberFormat(profitData.total_profit)}`"
          t-icon="attach_money"
          t-icon-color=""
          b-icon=""
          b-icon-color="success"
          :b-text="
            `이번 주, android : ${$options.filters.numberFormat(
              profitData.android.weekly_profit
            )} / ios : ${$options.filters.numberFormat(profitData.ios.weekly_profit)}`
          "
        />
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="col-12">
        <p>입금 내역(최근 3일)</p>
        <v-simple-table class="simple-table" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  입금계좌명
                </th>
                <th class="text-left">
                  금액
                </th>
                <th class="text-left">
                  시간
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="accountLog in accountLogs" :key="accountLog.id">
                <td>{{ accountLog.remark1 }}</td>
                <td>{{ accountLog.accountIn }}</td>
                <td>{{ accountLog.transactionTime }}</td>
                <td>
                  <v-btn x-small @click="onClickCheck('account-log', accountLog.id)">확인</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        ><span class="small-text" v-if="lastUpdatedAt">마지막 업데이트 시간 {{ lastUpdatedAt }}</span></v-col
      >
    </v-row>
    <v-row class="mt-5">
      <v-col cols="col-12">
        <p>
          지원 내역(최근 3일)
        </p>
        <v-simple-table class="simple-table" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  task id
                </th>
                <th class="text-left">
                  간병인명
                </th>
                <th class="text-left">
                  환자명
                </th>
                <th class="text-left">
                  날짜
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody class="max">
              <tr v-for="task in taskApplies" :key="task.task_idx">
                <td>{{ task.task_idx }}</td>
                <td>{{ task.caregiver_user_name }}</td>
                <td>{{ task.patient_user_name }}</td>
                <td>{{ transformDate(task.task_created_at) }}</td>
                <td>
                  <v-btn x-small @click="movePatient(task.task_patient_idx)">이동</v-btn>
                </td>
                <td>
                  <v-btn x-small @click="onClickCheck('task-apply', task.task_idx)">확인</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="col-12">
        <p>매칭 내역(최근 3일)</p>
        <v-simple-table class="simple-table" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  task id
                </th>
                <th class="text-left">
                  간병인명
                </th>
                <th class="text-left">
                  환자명
                </th>
                <th class="text-left">
                  날짜
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody class="max">
              <tr v-for="task in taskDones" :key="task.task_idx">
                <td>{{ task.task_idx }}</td>
                <td>{{ task.caregiver_user_name }}</td>
                <td>{{ task.patient_user_name }}</td>
                <td>{{ transformDate(task.task_updated_at) }}</td>
                <td>
                  <v-btn x-small @click="movePatient(task.task_patient_idx)">이동</v-btn>
                </td>
                <td>
                  <v-btn x-small @click="onClickCheck('task-done', task.task_idx)">확인</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12">
        <p class="text-subtitle">
          환자, 간병인 등록현황 (최근 30일)
        </p>
        <LineChart v-if="true" :chartdata="users_daily" :options="options" />
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12">
        <p class="text-subtitle">
          매칭현황 (최근 30일)
        </p>
        <LineChart v-if="true" :chartdata="match_daily" :options="options" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import Config from '@/config'
import axios from 'axios'
import StatsApi from './api/stats'
import LineChart from './components/LineChart'
import SmallCard from './components/SmallCard'

export default {
  components: {
    LineChart,
    SmallCard
  },
  data() {
    return {
      isLoading: false,
      startDate: '',
      endDate: '',
      carerData: {},
      patientData: {},
      matchData: {},
      profitData: {
        total_profit: 0,
        android: {
          weekly_profit: 0
        },
        ios: {
          weekly_profit: 0
        }
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
      users_daily: null,
      match_daily: null,
      accountLogs: [],
      taskApplies: [],
      taskDones: [],
      autoSender: null,
      useAutoSender: false,
      interval: null,
      lastUpdatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      buttonLoading: false
    }
  },
  async created() {
    await this.setup()
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    async setup() {
      const now = dayjs()
      this.endDate = now.format('YYYY-MM-DD')
      const startDate = now.subtract(30, 'day')
      this.startDate = startDate.format('YYYY-MM-DD')

      await this.getAutoSender()
      await this.getUsers()
      await this.getMatchData()

      await this.getAccountLogs()
      await this.getCaregiverTasksApply()
      await this.getCaregiverTasksDone()

      this.interval = setInterval(async () => {
        this.lastUpdatedAt = null
        await this.getAccountLogs()
        await this.getCaregiverTasksApply()
        await this.getCaregiverTasksDone()
        this.lastUpdatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss')
      }, 30000)

      this.profitData = await StatsApi.getProfit()
    },
    async setConfirm() {
      if (window.confirm('자동 메세지 기능을 활성화 하기전에 새로고침하여 현재 상태를 확인하세요.')) {
        alert('ture')
      } else {
        alert('false')
      }
    },

    async getUsers() {
      this.carerData = await StatsApi.getCaregivers(this.startDate, this.endDate)
      this.patientData = await StatsApi.getPatients(this.startDate, this.endDate)
      const labels = this.carerData.daily_count.map(item => item.date)
      const carerData = this.carerData.daily_count.map(item => item.count)
      const patientData = this.patientData.daily_count.map(item => item.count)

      this.users_daily = {
        labels,
        datasets: [
          {
            label: '간병인',
            backgroundColor: '#00D6AF',
            data: carerData
          },
          {
            label: '환자',
            backgroundColor: '#00AEFF',
            data: patientData
          }
        ]
      }
    },
    async setAutoSender() {
      if (window.confirm('자동 보내기 기능을 변경하기전에 새로고침하여 현재 상태를 확인해주세요.')) {
        this.buttonLoading = true
        await axios.post(`${Config.Api.Service2.url}/auto-sender`, {
          isStart: !this.useAutoSender
        })
        this.buttonLoading = false
        this.useAutoSender = !this.useAutoSender
      }
    },
    async getAutoSender() {
      const { data } = await axios.get(`${Config.Api.Service2.url}/auto-sender`)
      this.autoSender = data
      this.useAutoSender = !!data.startedAt
    },
    async getMatchData() {
      this.matchData = await StatsApi.getMatch(this.startDate, this.endDate)
      const labels = this.matchData.daily_count.map(item => item.date)
      const data = this.matchData.daily_count.map(item => item.count)

      this.match_daily = {
        labels,
        datasets: [
          {
            label: '매칭 건수',
            backgroundColor: '#0059BC',
            data
          }
        ]
      }
    },
    async getAccountLogs() {
      const value = window.localStorage.getItem('checkedNotis') || ''

      axios.get(`${Config.Api.Service2.url}/account-log`).then(
        ({ data }) =>
          (this.accountLogs = data.filter(v => {
            return value.search(`account-log${v.id}`) === -1
          }))
      )
    },
    async getCaregiverTasksApply() {
      const value = window.localStorage.getItem('checkedNotis') || ''

      axios
        .get(`${Config.Api.Service2.url}/caregiver-task`, {
          params: {
            state: 'apply'
          }
        })
        .then(
          ({ data }) =>
            (this.taskApplies = data.filter(v => {
              return value.search(`task-apply${v.task_idx}`) === -1
            }))
        )
    },
    async getCaregiverTasksDone() {
      const value = window.localStorage.getItem('checkedNotis') || ''

      axios
        .get(`${Config.Api.Service2.url}/caregiver-task`, {
          params: {
            state: 'done'
          }
        })
        .then(
          ({ data }) =>
            (this.taskDones = data.filter(v => {
              return value.search(`task-done${v.task_idx}`) === -1
            }))
        )
    },

    onClickCheck(target, id) {
      const value = window.localStorage.getItem('checkedNotis') || ''

      window.localStorage.setItem('checkedNotis', `${value},${target}${id}`)
      console.log(`${value},${target}${id}`)
      if (target === 'account-log') {
        this.accountLogs = this.accountLogs.filter(v => id !== v.id)
      } else if (target === 'task-apply') {
        this.taskApplies = this.taskApplies.filter(v => id !== v.task_idx)
      } else if (target === 'task-done') {
        this.taskDones = this.taskDones.filter(v => id !== v.task_idx)
      }
    },
    movePatient(id) {
      this.$router.push('/patients/' + id)
    },
    transformDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped></style>
